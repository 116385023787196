



















































































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import Copy16 from '@carbon/icons-vue/es/copy/16'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import EventSubscriptionTypeDropdown from '@/partials/forms/Events/EventSubscriptionTypeDropdown.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import MoneyInput from '@/partials/forms/components/MoneyInput.vue'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'

type VoucherItens = Array<
  {
    id: string;
    voucherId: string;
  }
>

@Component({
  components: {
    Copy16,
    EventSubscriptionTypeDropdown,
    PasswordConfirmField,
    MoneyInput,
    ValidationObserver,
    ValidationProvider,
    NotifyOnFailed
  }
})
export default class EventSubscriptionBatch extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) title!: string
  done = true
  form = {
    amount: 0,
    eventSubscriptionTypeId: '',
    institution: '',
    paid: 0,
    confirm: '',
    eventId: '',
    errors: {
      amount: '',
      eventSubscriptionTypeId: '',
      institution: '',
      paid: '',
      confirm: ''
    }
  }

  links: VoucherItens = []
  copiedModal = false

  created () {
    Object.assign(this.form, {
      amount: 10,
      institution: 'Indicatest',
      paid: 119.90
    })
    this.form.eventId = this.eventId
  }

  submit () {
    this.done = false
    axios.post(`/event/${this.eventId}/subscription/batch`, snakeCaseKeys(this.form, { deep: true }))
      .then(response => {
        this.links = camelCaseKeys(response.data.data, { deep: true })
        const flashMessage: flashMessage = {
          message: 'Lote gerado com sucesso.',
          type: 'success'
        }

        this.setFlashMessage = this.setFlashMessage(flashMessage)
      })
      .finally(() => { this.done = true })
  }

  get linksFormatted () {
    return this.links.map(link => {
      return `${link}`
    }).join('\n\n')
  }

  copyVoucherLinks () {
    Vue.nextTick()
      .then(() => {
        const voucherField = document.getElementById('voucher-links') as HTMLInputElement

        voucherField.select()
        voucherField.setSelectionRange(0, 9999999)

        document.execCommand('copy')

        this.toggleModal('copiedModal')
      })
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }

  resetErrors () {
    this.form.errors = {
      amount: '',
      institution: '',
      eventSubscriptionTypeId: '',
      paid: '',
      confirm: ''
    }
  }
}
