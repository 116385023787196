






import { Component, Vue } from 'vue-property-decorator'

import EventSubscriptionBatchForm from '@/partials/forms/Events/SubscriptionBatchForm.vue'
import CenteredThin from '@/layouts/Panels/CenteredThin30-50.vue'

@Component({
  components: {
    CenteredThin,
    EventSubscriptionBatchForm
  }
})
export default class EventSubscriptionBatchCreate extends Vue {

}
