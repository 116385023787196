var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl font-bold mb-4"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid lg:grid-cols-12 gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"lg:col-span-12",attrs:{"name":"institution","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"invalid-message":_vm.form.errors.institution || errors[0],"label":"Instituição"},model:{value:(_vm.form.institution),callback:function ($$v) {_vm.$set(_vm.form, "institution", $$v)},expression:"form.institution"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-12",attrs:{"name":"subscription type","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('EventSubscriptionTypeDropdown',{attrs:{"event-id":_vm.eventId,"invalid-message":_vm.form.errors.eventSubscriptionTypeId || errors[0]},model:{value:(_vm.form.eventSubscriptionTypeId),callback:function ($$v) {_vm.$set(_vm.form, "eventSubscriptionTypeId", $$v)},expression:"form.eventSubscriptionTypeId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-7",attrs:{"name":"paid","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MoneyInput',{attrs:{"label":"Valor pago por Inscricão","invalid-message":_vm.form.errors.paid || errors[0]},model:{value:(_vm.form.paid),callback:function ($$v) {_vm.$set(_vm.form, "paid", $$v)},expression:"form.paid"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"lg:col-span-5",attrs:{"name":"amount","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-number-input',{attrs:{"label":"Quantidade","invalid-message":_vm.form.errors.amount || errors[0]},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})]}}],null,true)})],1),_c('PasswordConfirmField',{staticClass:"my-4 lg:w-2/3",attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}}),(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mb-6",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])}),(_vm.linksFormatted.length)?_c('cv-button',{staticClass:"whitespace-no-wrap mb-4",attrs:{"size":"small","kind":"secondary"},on:{"click":function($event){return _vm.copyVoucherLinks()}}},[_vm._v(" Copiar Links dos Vouchers "),_c('div',{staticClass:"flex items-center"},[_c('Copy16',{staticClass:"bx--btn__icon"})],1)]):_vm._e(),(_vm.linksFormatted.length)?_c('cv-text-area',{staticClass:"h-64 mb-16",attrs:{"id":"voucher-links","label":"Os Vouchers abaixo já foram enviados para o seu e-mail.","rows":"16","readonly":""},model:{value:(_vm.linksFormatted),callback:function ($$v) {_vm.linksFormatted=$$v},expression:"linksFormatted"}}):_vm._e(),_c('cv-modal',{attrs:{"close-aria-label":"Fechar","size":"xs","visible":_vm.copiedModal,"auto-hide-off":true},on:{"modal-hide-request":function($event){return _vm.toggleModal('copiedModal')},"primary-click":function($event){return _vm.toggleModal('copiedModal')}}},[_c('template',{slot:"title"},[_c('div',{staticClass:"text-2xl"},[_vm._v(" Vouchers copiados! ")])]),_c('template',{slot:"primary-button"},[_vm._v(" Ok ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }